import React from "react"
import SEO from "../components/Seo"
import Icon from "../components/Icon"
import Layout from "../components/Layout"
import MultiVideoPlayer from "../components/MultiVideoPlayer"
import Resource from "../components/Resource"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import { ImageVideoModal } from "../components/Modal"

const VFTPage = () => {
  return (
    <Layout>
      <SEO title="Just The Facts" />

      {/***************  HERO ***************/}
      <Section className="hero">
        <Container>
          <h1>Be Vape Free: Just the Facts Virtual Field Trip</h1>
          <Row>
            <Column span={7}>
              <h3 className="hero__subhead">
                Join us for a real-world virtual learning opportunity to get the
                facts on the vaping epidemic.
              </h3>
            </Column>
          </Row>
        </Container>
      </Section>

      {/******************** BODY *********************/}

      {/*********** REGISTER ***********/}
      {/*
      <Section>
        <Container>
          <h2 class="serif">Be Vape Free: Just the Facts Virtual Field Trip</h2>
          <h3>A Student Health Whodunit!</h3>
          <Row>
            <Column span="7">
              <Image filename="just-the-facts-vft-still.jpg" />
            </Column>
            <Column span="5">
              <h3 className="resource__title">PREMIERES: APRIL 29, 2021</h3>
              <p>
                Inspire Vape Detectives to uncover the harsh truth about
                e-cigarettes for themselves in this exciting virtual
                exploration. Students will become E-cigarette Sleuths collecting
                shocking evidence that proves just how villainous vaping’s
                effects are on the brain, heart, and lungs. Immerse your class
                in the science of addiction by tracking vape aerosol right from
                the first e-cigarette puff all the way to the brain! A variety
                of real-world experts will join to help students make the case
                to protect their bodies and choose a life free from addiction.
              </p>
              <p>
                <Button
                  href="https://form.jotform.com/210415688873161"
                  target="_blank"
                >
                  Register
                </Button>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      */}

      <Section>
        <Container>
          <h2 class="serif">Be Vape Free: Just the Facts Virtual Field Trip</h2>
          <Row className="mb-1 mt-1">
            <Column>
            <ImageVideoModal
              imgPlaceholder="theme/posters/just-the-facts/video-01.jpg"
              src="https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/virtual-field-trip-just-the-facts.mp4"
            />
              {/* <MultiVideoPlayer
                videos={[
                  {
                    src: "https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/virtual-field-trip-just-the-facts.mp4",
                    title: "Just the Facts Full VFT",
                    posterSrc: "theme/posters/just-the-facts/video-01.jpg",
                  },
                  {
                    guidSrc: "d5b12166-9897-46c8-820a-f548e8c27f32",
                    title: "Introduction",
                    posterSrc: "theme/posters/just-the-facts/video-intro.jpg",
                  },
                  {
                    guidSrc: "c7f5d5ba-0ec1-4d57-ab5b-a28f82b56c46",
                    title: "What's in the e-liquid?",
                    posterSrc: "theme/posters/just-the-facts/video-03.jpg",
                  },
                  {
                    guidSrc: "2115a595-1fbe-4bf3-9ce7-46d975fa7138",
                    title: "Vaping impacts inside the body",
                    posterSrc: "theme/posters/just-the-facts/video-02.jpg",
                  },
                  {
                    guidSrc: "f61cf714-b9f1-4b6e-aa52-218ae526d006",
                    title: "The science of addiction",
                    posterSrc: "theme/posters/just-the-facts/video-04.jpg",
                  },
                  {
                    guidSrc: "5bc46ebd-f379-483c-8db5-ac18a9d3d445",
                    title: "Closing the case against vaping",
                    posterSrc: "theme/posters/just-the-facts/video-05.jpg",
                  },
                ]}
              /> */}
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <h2>A Student Health Whodunit!</h2>
              <p>
                Inspire Vape Detectives to uncover the harsh truth about
                e-cigarettes for themselves in this exciting virtual
                exploration. Students will become E-cigarette Sleuths collecting
                shocking evidence that proves just how villainous vaping’s
                effects are on the brain, heart, and lungs. Immerse your class
                in the science of addiction by tracking vape aerosol right from
                the first e-cigarette puff all the way to the brain! A variety
                of real-world experts will join to help students make the case
                to protect their bodies and choose a life free from addiction.
              </p>
            </Column>
            <Column span={6}>
              <div className="socialcard">
                <div className="socialcard__left">
                  <Icon name="twitter" />
                </div>
                <div className="socialcard__right">
                  <h2>#BeVapeFree</h2>
                  <a
                    href="https://twitter.com/hashtag/BeVapeFree"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialcard__right-link"
                  >
                    View conversation on Twitter
                    <Icon name="externallink" />
                  </a>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  VFT Survey ***************/}
      {/* <Section className="homepage__callout">
        <Container>
          <a
            href="https://surveys.discoveryeducation.com/s3/Be-Vape-Free-Just-the-Facts-Virtual-Field-Trip-Survey"
            target="_blank"
            rel="noopener noreferrer"
            className="cta"
          >
            Watched the Virtual Field Trip? Share your feedback with us!
            <Icon name="rightarrow" />
          </a>
        </Container>
      </Section> */}

      {/**********************  Educational Guide **************************/}

      <Section className="yellow-pattern">
        <Container>
          <Row className="mb-1">
            <h2>Just The Facts: A Be Vape Free VFT Educator Guide</h2>
          </Row>
        </Container>
        <Resource
          image="img-vft-educator-guide-v2.jpg"
          title="Virtual Field Trip Educator Guide"
          description={
            <>
              <p>
                Give students all the clues they need to solve the mystery of
                the vaping epidemic using this companion guide. Find ways to
                connect important concepts to students’ everyday lives using key
                vocabulary, learning objectives, and activities for before{" "}
                <em>and </em>
                after watching the Virtual Field Trip.
              </p>
            </>
          }
          actions={[
            {
              label: "Educator Guide PDF",
              path: "/resources/CVS-Catch-VFTEducatorGuide-V01.1.pdf",
              type: "download",
            },
          ]}
          horizontal
        />
      </Section>
    </Layout>
  )
}

export default VFTPage
